
import {defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {NOTIFICATION_CODE, NOTIFICATION_TYPE} from "@/core/config/Constant";
import {ClientSelect} from "@/core/composite/composite";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import NotificationService from "@/core/services/NotificationService";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import * as Yup from "yup";
import UserListSelect from "@/components/common/UserListSelect.vue";

export default defineComponent({
  name: "NotificationForm",
  components: {UserListSelect, FormErrorMessage, BaseRadio, BaseSelect, BaseForm},
  props: {
    id: {type: String},
    noti: {type: Object, required: true}
  },
  emits: ['save'],
  setup(props) {
    const model = ref<any>(props.noti);
    watch(() => props.noti, cb => {
      model.value = cb;
    })
    const submitting = ref(false);
    const validator = Yup.object().shape({
      code: Yup.string().required().label("Code"),
    });
    return {
      validator,
      model,
      submitting,
      NOTIFICATION_CODE,
      NOTIFICATION_TYPE,
      ...ClientSelect(true),
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true
      if (this.id) {
        NotificationService.update(this.id, this.model).then(res => {
          this.$emit('save', res);
        }).finally(() => {
          this.submitting = false;
        })
      } else {
        NotificationService.create(this.model).then(res => {
          this.$emit('save', res)
        }).finally(() => {
          this.submitting = false
        })
      }
    }
  }
})

