
import {computed, defineComponent, onMounted, ref} from "vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import store from "@/store";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import NotificationForm from "@/views/notification/NotificationForm.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import NotificationService from "@/core/services/NotificationService";
import Swal from "sweetalert2";

export default defineComponent({
  name: "List",
  components: {Avatar, NotificationForm, BaseModal, Entities, KtDatatable, QuickAction},
  props: {
    clientId: {type: String, required: false, default: () => ''}
  },
  setup(props) {
    const currentUser = computed(()=>store.getters.currentUser);
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Notifications",
        [
          {link: false, router: '', text: 'Notifications'}
        ]
      )
    })
    const tableHeader = ref([
      {
        name: "Client",
        key: "client",
        sortable: true,
      },
      {
        name: "Code",
        key: "code",
        sortable: true,
      },
      {
        name: "Type",
        key: "type",
        sortable: true,
      },
      {
        name: "Users",
        key: "users",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    
    const objectFilters = ref({clientId: props.clientId})
    const page = computed(() => store.state.NotificationModule.page);
    const state = ref({
      name: '',
      entity: {code: 'CASE_CREATE', type: 'EMAIL', requestClientId: '', requestUserIds: []},
      id: ''
    })
    return {
      state,
      tableHeader,
      page,
      ...LoadFilterObjects(Actions.LOAD_NOTIFICATIONS, objectFilters.value, ['client']),
      currentUser,
    }
  },
  methods: {
    onAdd() {
      this.state.name = 'Add Notification'
      this.state.entity = {code: 'CASE_CREATE', type: 'EMAIL', requestClientId: '', requestUserIds: []}
      this.state.id = ''
      const modal = this.$refs.formNotificationRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onEdit(entity) {
      const ids = entity.users.map(item => item.hashId);
      this.state.entity = {code: entity.code, type: entity.type, requestClientId: entity.client.id, requestUserIds: ids}
      this.state.id = entity.id
      const modal = this.$refs.formNotificationRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await NotificationService.delete(id).then(() => {
            this.paginationLoad()
          })
        }
      })
    },
    onSave() {
      const modal = this.$refs.formNotificationRef as typeof BaseModal;
      modal.hideBaseModal();
      this.paginationLoad();
    }
  }
})
